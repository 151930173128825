<template>
    <v-dialog v-model="show" @keydown.esc="$emit('close')" persistent scrollable max-width="800">
        <v-card>
            <v-card-title class="headline primary white--text">Listagem de importação</v-card-title>

            <v-card-text>
                <v-alert dense outlined type="error" v-if="listWithFilter.length == 0" class="mt-12">
                    Não possui nenhum item para mostrar.
                    <strong v-if="hideCreated"><br><br>Desabilite o filtro para esconder os sucessos</strong>
                </v-alert>
                <v-data-table v-else :headers="headers" :items="listWithFilter" :items-per-page="-1" class="elevation-1" />
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-switch v-model="hideCreated" :label="`Esconder criado com sucesso`"></v-switch>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="$emit('close')">
                    <v-icon dark left>cancel</v-icon>
                    {{ $t("Fechar") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        items: {
            type: Array,
        },
    },
    data() {
        return {
            hideCreated: true,
            headers: [
                { text: 'NEC', value: 'idNec' },
                { text: 'Código do pedido', value: 'order' },
                { text: 'Loja', value: 'store' },
                { text: 'Status', value: 'status' },
            ],
        }
    },
    computed: {
        listWithFilter() {
            return this.hideCreated ? this.items.filter(e => e.status != 'CREATED') : this.items;
        },
    },
}
</script>